.trailsText {
  position: relative;
  width: 100%;
  height: 80px;
  line-height: 80px;
  color: black;
  font-size: 6em;
  font-weight: 800;
  letter-spacing: -0.05em;
  will-change: transform, opacity;
  overflow: hidden;
}

.trailsText > div {
  padding-right: 0.05em;
  overflow: hidden;
}

.container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.ticker {
  position: absolute;
  max-height: 4rem;
  max-width: 4rem;
  width: 3.5vw;
  height: 3.5vw;
  bottom: 2rem;
  left: 2rem;
}

.ticker>* {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.ticker>div {
  opacity: 0.2;
  border: solid 3px black;
}

.ticker>svg {
  fill: none;
  stroke: black;
  stroke-width: 3;
  stroke-dasharray: 120;
  stroke-dashoffset: 120;
}