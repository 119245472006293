.container > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:grab;
  }
  
  .text {
    pointer-events: none;
    justify-content: start !important;
    font-family: 'Open Sans', sans-serif;
    line-height: 0px;
    text-transform: uppercase;
  }
  
  .number {
    font-size: 300px;
    color: #545864;
  }
  
  .number span {
    display: inline-block;
    position: relative;
  }
  
  .slopeBegin {
    background-color: #20232f;
    clip-path: polygon(20% 0, 70% 0, 50% 100%, 0% 100%);
  }
  
  .slopeEnd {
    clip-path: polygon(70% 0, 100% 0, 80% 100%, 50% 100%);
  }
  
  .slopeBegin,
  .slopeEnd {
    position: absolute;
    width: 170%;
    height: 100%;
    cursor: pointer;
  }
  
  .pink {
    background: linear-gradient(to right, deeppink 0%, coral 100%);
  }
  
  .teal {
    background: linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%);
  }
  
  .tomato {
    background: linear-gradient(to right, tomato 0%, gold 100%);
  }
  
  .rose {
    background: linear-gradient(to right, deeppink 0%, DeepSkyBlue 100%);
  }

  .dink{
    background: linear-gradient(to right, gold 0%, DeepSkyBlue 100%);
  }

  .magento{
    background: linear-gradient(to right, deeppink 0%, deeppink 100%);
  }