.sh-spin{animation:dg-spin 2s infinite linear}@keyframes dg-spin{0%{transform:rotate(0deg)}100%{transform:rotate(359deg)}}
.sh-animate-fading{animation:fading 10s infinite}@keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}
.sh-animate-opacity{animation:opac 0.6s}@keyframes opac{from{opacity:0} to{opacity:1}}
.sh-animate-top{position:relative;animation:animatetop 0.4s}@keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}
.sh-animate-left{position:relative;animation:animateleft 0.4s}@keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}
.sh-animate-right{position:relative;animation:animateright 0.4s}@keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}
.sh-animate-bottom{position:relative;animation:animatebottom 0.8s}@keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}
.sh-animate-zoom {animation:animatezoom 0.6s}@keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}}
.sh-animate-input{transition:width 0.4s ease-in-out}.sh-animate-input:focus{width:100%!important}

@keyframes loading{from{transform:rotate(0)}to{transform:rotate(360deg)}}.loader{position:fixed;top:0;left:0;right:0;bottom:0;display:flex;align-items:center;justify-content:center}.loader:after{content:"";position:relative;border:2px solid rgba(0,0,0,.6);border-bottom:2px solid transparent;border-radius:50%;width:18px;height:18px;animation:loading 1s linear infinite}