/* React Multi Carousel Custom css */
.heightFull,
.heightFull .carouselWrapper,
.heightFull .carouselWrapper > .swiper-container,
.heightFull .carouselWrapper > .swiper-container > .swiper-wrapper,
.heightFull
	.carouselWrapper
	> .swiper-container
	> .swiper-wrapper
	> .swiper-slide {
	height: 100%;
}
.swiper-wrapper .swiper-slide {
	padding-bottom: 2px;
	height: auto;
}
.swiper-container .swiper-button-prev,
.swiper-container .swiper-button-next {
	display: none;
}
.carouselWrapper
	.swiper-container
	.swiper-pagination-bullets
	.swiper-pagination-bullet {
	width: 8px;
	height: 8px;
	border: 0;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	background-color: #fff;
	border-radius: 8px;
	transition: all 0.3s ease-in-out;
	opacity: 1;
}
.carouselWrapper .swiper-container .swiper-pagination-bullets {
	bottom: 10px;
}
.carouselWrapper
	.swiper-container
	.swiper-pagination-bullets
	.swiper-pagination-bullet.swiper-pagination-bullet-active {
	width: 16px;
	background-color: #000;
}
@media screen and (min-width: 480px) {
	.carouselWrapper .swiper-container .swiper-pagination-bullets {
		bottom: 20px;
	}
	.carouselWrapper
		.swiper-container
		.swiper-pagination-bullets
		.swiper-pagination-bullet {
		width: 12px;
		height: 12px;
	}
	.carouselWrapper
		.swiper-container
		.swiper-pagination-bullets
		.swiper-pagination-bullet.swiper-pagination-bullet-active {
		width: 20px;
	}
}
@media screen and (min-width: 1025px) {
	.carouselWrapper .swiper-container .swiper-pagination-bullets {
		bottom: 28px;
	}
	.carouselWrapper.pagination-left .swiper-pagination-bullets {
		bottom: 52px;
	}
	.carouselWrapper
		.swiper-container
		.swiper-pagination-bullets
		.swiper-pagination-bullet {
		width: 16px;
		height: 16px;
		margin-left: 8px;
		margin-right: 8px;
	}
	.carouselWrapper
		.swiper-container
		.swiper-pagination-bullets
		.swiper-pagination-bullet.swiper-pagination-bullet-active {
		width: 28px;
	}
}

.carouselWrapper.dotsCircle
	.swiper-container
	.swiper-pagination-bullets
	.swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-left: 4px;
	margin-right: 4px;
	opacity: 0.5;
	transform: scale(0.7);
}
.carouselWrapper.dotsCircle
	.swiper-container
	.swiper-pagination-bullets
	.swiper-pagination-bullet.swiper-pagination-bullet-active {
	background-color: #fff;
	opacity: 1;
	transform: scale(1);
}