.main {
  min-width: 100px;
  padding: 0 20px;
  margin: 0 auto;
  height: 260px;
}

.transitionsItem {
  overflow: hidden;
  width: 100%;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 3em;
  font-weight: 800;
  text-transform: uppercase;
  will-change: transform, opacity, height;
  white-space: nowrap;
  cursor: pointer;
  line-height: 80px;
}

.container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
